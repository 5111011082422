<template>
  <v-app id="inspire">
    <v-main>
      <v-container fill-height fluid class="background-image">
        <v-layout align-center justify-start fill-height>
          <v-flex xs12 sm8 md6 lg4>
            <v-card elevation="12">
              <!-- Header -->
              <v-toolbar dense dark color="primary darken-1">
                <v-toolbar-title>IRM Smart Pipeline Data</v-toolbar-title>
              </v-toolbar>

              <!-- Main content -->
              <v-card-text>
                <!-- Form -->
                <v-form lazy-validation>
                  <v-text-field
                    v-model="username"
                    :error-messages="nameErrors"
                    label="Username"
                    placeholder="Username"
                    required
                    @input="$v.username.$touch()"
                    @blur="$v.username.$touch()"
                    @keydown="formKeydown"
                    prepend-icon="person"
                    clearable
                  />
                  <v-text-field
                    v-model="password"
                    :error-messages="passwordErrors"
                    label="Password"
                    placeholder="Password"
                    required
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                    @keydown="formKeydown"
                    prepend-icon="lock"
                    type="password"
                    clearable
                  />
                </v-form>
              </v-card-text>
              <!-- Buttons -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary darken-1"
                  @click="submitLogin"
                  :loading="authPending"
                  :disabled="$v.$invalid"
                >Sign in</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <v-snackbar
        v-if="sessionExpired"
        v-model="snackbar"
        bottom
        color="warning darken-1"
      >
        <v-icon dark>warning</v-icon>

        Session expired

        <template #action>
          <v-btn
            dark
            icon
            @click="snackbar = false"
          >
            <v-icon dark>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'pibot-login-page',
  created () {
    this.logout()
    this.snackbar = this.sessionExpired
  },
  validations: {
    username: { required },
    password: { required }
  },
  data () {
    return {
      username: '',
      password: '',
      numTries: 0,
      snackbar: false
    }
  },
  computed: {
    ...mapState({
      auth: state => state.account.auth,
      authPending: state => state.account.authPending
    }),
    nameErrors () {
      const errors = []
      if (!this.$v.username.$dirty) return errors
      !this.$v.username.required && errors.push('Username is required.')
      this.numTries && !this.auth && !this.authPending && errors.push('Username may be incorrect.')
      return errors
    },
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required')
      this.numTries && !this.auth && !this.authPending && errors.push('Password may be incorrect.')
      return errors
    },
    sessionExpired () {
      return !(!this.$router.currentRoute.query.session_expired) // Not undefined
    }
  },
  methods: {
    ...mapActions({
      tryLogin: 'account/socket_tryLogin',
      logout: 'logout'
    }),
    submitLogin () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      const credentials = {
        username: this.username,
        password: this.password
      }

      this.numTries++
      this.tryLogin(credentials).then(() => {
        this.numTries = 0
        const { redirect } = this.$router.currentRoute.query
        this.$router.push(redirect || { name: 'mapviewer' })
        // Further logics are handled by the store
      })
    },
    formKeydown (event) {
      if (event.key === 'Enter') {
        this.submitLogin()
      }
    }
  }
}
</script>

<style scoped>
.background-image {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url("./spd-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
